// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // version data
  version: "1.0.0",
  // secrets
  secretQR: "myC0n3*t1on*TkN",
  secretApi: "AC7B638365CEE713CDD183FD8B882",
  secretToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJNeSBDb25leHRpb24iLCJwcm9qZWN0IjoiVmVoaWNsZXMgQ29tcGFyaXRpb24ifQ.02UStAlhiKBwrE6vTx44fdvIMbiwiT2p0_gKhsQbrFA",
  secretVehapi: "iNtl8Xyl780ixjuTULP0fAuUF5cZ9Ul3Yj0UoqmQ",
  // api data
  spname: 'MYCONEXTION',
  custLoginId: 'MFL9876YCONEXTION',
  pswd: 'orlando',
  contractNumber: 'FL9876',
  requestUrl: 'https://tst-asamsvc.assuranceamerica.com/asamservices/policyservice.asmx',
  // server data
  server_api: 'https://api.credipremium.co',
  server_media: 'https://api.credipremium.com/uploads/',
  appURI: 'https://app-dev.credipremium.co',
  websiteURI: 'https://credipremium.co',
  websiteURITxt: 'www.credipremium.co',
  // media data
  maxFileData: 5242880,
  // country data
  countryNameEn: 'United States',
  countryNameEs: 'Estados Unidos',
  countryFlag: 'assets/img/flags/USA.png',
  countryCode: '1',
  countryCodeId: 'bf093fa0-e2e6-4a48-b64a-563472b7452d',
  // map data
  googleMapKey: 'AIzaSyCcUTVa2PHNspNn4_zJpDjF1dlkkKvEc6M',
  mapBoxKey: 'pk.eyJ1IjoibXljb25leHRpb24iLCJhIjoiY2xudDJmYmpqMWhhczJscGc3d21taWx1MyJ9.QJSeXcrp9MELuBdfZphaTg',
  lat: 6.22900264129868,
  lng: -75.57837234758941,
  zoom: 16,
  // unlock data
  unlockTimeNumber: 1,
  unlockTimeMessure: 'minutes',
  unlockTime: 3000000,
  // colors data
  colors: {
    chartColors: [
      '#FF5E00',
      '#C24700',
      '#AB3F00',
      '#FF904F',
      '#FF7424',
      '#702900',
      '#4F1D00',
      '#181818',
      '#373435',
    ],
  },
};
