import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get data from storage
    const token = environment.secretVehapi;
    const publicApi = localStorage.getItem('publicApi');
    // init authorization
    let authorization: any;
    // check token
    if(token == null || token == undefined) {
      authorization ='token_not_required';
    }else{
      authorization = token;
    }
    // init headers
    let headers = new HttpHeaders();
    // check if api is public
    if (publicApi == 'true') {
      // encrypt signature
      const hash = CryptoJS.HmacSHA256(JSON.stringify(req.body), environment.secretApi).toString(
        CryptoJS.enc.Hex,
      );
      headers = new HttpHeaders(
        {
          Authorization: 'Bearer ' + authorization,
          'x-signature': hash
        }
      );
    } else {
      headers = new HttpHeaders(
        {
          Authorization: 'Bearer ' + authorization
        }
      );
    }
    // set reqClone
    const reqClone = req.clone({
      headers,
    });
    return next.handle(reqClone);
  }
  async messageError(error: HttpErrorResponse) {
  }
}